<template>
  <div
    class="border p-x5 rounded-x1 relative min-h-[180px] flex flex-col items-center justify-center"
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="dropFile"
    :class="{
      'bg-accent-color/30': active,
      'bg-white': !active && !grey,
      'bg-primary-grey-background': !active && grey,
      'border-border-color': !error,
      '!border-error': error,
      'border-dashed border-2': dashed,
      'rounded-x1': !rounded2,
      'rounded-x2': rounded2,
    }"
  >
    <label
      for="file-upload"
      class="flex flex-col gap-x1 items-center justify-center cursor-pointer"
    >
      <div class="flex justify-center mb-5">
        <img
          src="~/assets/img/upload-cloud-new.svg"
          alt="cloud upload icon"
          class="w-5 h-5"
        />
      </div>

      <div class="flex gap-x1 items-center">
        <span
          class="text-sm cursor-pointer leading-base text-accent-color font-medium text-center"
        >
          {{ text?.length ? text : "Click to upload" }}
        </span>
        <input
          id="file-upload"
          name="file-upload"
          type="file"
          class="sr-only"
          :accept="
            format?.length
              ? format
              : 'image/bmp, image/png, image/jpg, image/jpeg,image/gif'
          "
          ref="file"
          @change="uploadFile"
        />
        <span
          v-if="!hasLabel"
          class="text-sm leading-base text-font-primary font-normal text-center"
        >
          or drag and drop
        </span>
        <slot name="label"></slot>
      </div>

      <span
        class="block text-xs leading-base-sm text-font-primary font-normal text-center"
      >
        {{ formatLabel?.length ? formatLabel : "" }}
        {{ size?.length ? size : "(max. 10mb)" }}
      </span>
    </label>
  </div>
</template>

<script setup lang="ts">
let props = defineProps({
  customer: Boolean,
  rounded2: Boolean,
  format: { type: String, default: "" },
  formatLabel: { type: String, default: "" },
  grey: { type: Boolean, default: false },
  size: { type: String, default: "" },
  text: { type: String, default: "" },
  noText: { type: Boolean, default: false },
  addNew: { type: Boolean, default: false },
  hasLabel: { type: Boolean, default: false },
  dashed: { type: Boolean, default: false },
  error: Boolean,
});
const emit = defineEmits(["uploadImage"]);

const active = ref(false);
let media: object = {};

function toggleActive() {
  active.value = !active.value;
}
function dropFile(event) {
  const image = {
    media: event.dataTransfer.files[0],
    title: event.dataTransfer.files[0].name,
  };
  emit("uploadImage", image);
}

function uploadFile(event) {
  const image = {
    media: event.target.files[0],
    title: event.target.files[0].name,
  };
  emit("uploadImage", image);
}
</script>
